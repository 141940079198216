import * as styles from './blog-post.module.css'
import { differenceInDays, formatDistance, format } from 'date-fns'
import AuthorList from './author-list'
import Container from './container'
import PortableText from './portableText'
import React from 'react'
import { buildImageObj } from '../../utils/bloghelpers'
import { imageUrlFor } from '../../utils/image-url'
import { Col, Row } from 'antd'
import BlogCategory from './blog-categories'
import RecentBlog from './recent-blog'
import BlogArchives from './blog-archives'
import { Link } from 'gatsby'
import slugify from 'slugify'

function BlogPost(props) {
	const { body, excerpt, authors, categories, title, mainImage, publishedAt, content } = props

	// TODO: refactor this code as it is mutating the props object, which is a bad idea

	body?.map(bodyBlock => {
		bodyBlock._type = bodyBlock.className.replace(/^./, function (str) {
			return str.toLowerCase()
		})
		bodyBlock.children &&
			bodyBlock.children.map(child => {
				child._type = child.className.replace(/^./, function (str) {
					return str.toLowerCase()
				})
			})
	})

	excerpt?.map(bodyBlock => {
		bodyBlock._type = bodyBlock.className.replace(/^./, function (str) {
			return str.toLowerCase()
		})
		bodyBlock.children &&
			bodyBlock.children.map(child => {
				child._type = child.className.replace(/^./, function (str) {
					return str.toLowerCase()
				})
			})
	})

	// formatt authors
	const handelAuthors = (
		<span className={styles.blueColor}>
			{authors?.length === 1 ? (
				<Link to={`/blogs?author=${slugify(authors[0].name, { replacement: '-' })}`}>
					{authors[0].name}
				</Link>
			) : (
				authors?.map(
					auth =>
						`${(
							<Link
								to={`blogs?author=${slugify(auth.name, {
									replacement: '-',
								})}`}
							>
								{auth.name}
							</Link>
						)}, `
				)
			)}
		</span>
	)
	// formatt Date
	const formattDate = (
		<span>
			{new Date(publishedAt).toLocaleDateString(
				{},
				{ timeZone: 'UTC', month: 'long', day: '2-digit', year: 'numeric' }
			)}
		</span>
	)
	// formatt categ
	const formattCategories = (
		<span className={styles.blueColor}>
			{categories?.length === 1 ? (
				<Link to={`/blogs?category=${slugify(categories[0]?.title, { replacement: '-' })}`}>
					{categories[0]?.title}
				</Link>
			) : (
				categories?.map(
					categ =>
						`${(
							<Link
								to={`/blogs?category=${slugify(categ.title, {
									replacement: '-',
								})}`}
							>
								{categ.title}
							</Link>
						)}, `
				)
			)}
		</span>
	)

	return (
		<article className={styles.root}>
			<header className={styles.header}>
				<h1 className={styles.title}>{title}</h1>
				<h5 className={styles.description}>
					By {handelAuthors} / {formattDate} / {formattCategories}
				</h5>
			</header>
			<main className={styles.mainContent}>
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					{/* <PortableText blocks={mainImage} /> */}
					<Col sm={24} md={18}>
						{mainImage && mainImage?.idengagerfile && (
							<div className={styles.mainImg}>
								<img src={mainImage?.externalurl} alt={mainImage?.alt} />
							</div>
						)}

						<div>
							{/* {body?.map(block => {
								return block?.children.map((span, idx) => (
									<p className={styles?.blogBlock} key={idx}>
										{span.text}
									</p>
								))
							})} */}
							{content ? (
								<div
									dangerouslySetInnerHTML={{
										__html: content,
									}}
								></div>
							) : (
								<PortableText blocks={body} />
							)}
						</div>
						<div className={styles.mainText}>
							{/* {excerpt.map(block => {
								return block?.children.map((span, idx) => (
									<p className={styles?.blogBlock} key={idx}>
										{span.text}
									</p>
								))
							})} */}
							{content ? (
								<div
									dangerouslySetInnerHTML={{
										__html: content,
									}}
								></div>
							) : (
								<PortableText blocks={excerpt} />
							)}
						</div>
					</Col>
					<Col sm={24} md={6} className={styles?.sideBlog}>
						<BlogCategory currentCateg={categories} />
						<RecentBlog />
						{/* <BlogArchives /> */}
					</Col>
				</Row>
			</main>
		</article>
	)
}

export default BlogPost
