import * as styles from "./author-list.module.css";
import React from "react";
import { buildImageObj } from "../../utils/bloghelpers";
import { imageUrlFor } from "../../utils/image-url";

function AuthorList({ items, title }) {
  return (
    <div className={styles.root}>
      <h2 className={styles.headline}>{title}</h2>
      <ul className={styles.list}>
        {items.map((author) => {
          const authorName = author && author.name;
          return (
            <li key={author.objectId} className={styles.listItem}>
              <div>
                <div className={styles.avatar}>
                  {author && author.image && author.image.idengagerfile && (
                    <img
                      //src={imageUrlFor(buildImageObj(author.image))}
                      src={imageUrlFor(author.image.externalurl)} width="100" height="100"
                      // .width(100)
                      // .height(100)
                      // .fit("crop")
                      // .url()}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div>
                {/* <h2 >author Detail {JSON.stringify(author)}</h2> */}
                <div>{authorName || <em>Missing name</em>}</div>
              </div>
            </li>
          );
        })}

      </ul>
    </div>
  );
}

export default AuthorList;
