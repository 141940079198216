import { graphql } from 'gatsby'
import BlogPost from '../components/blog/blog-post'
import React from 'react'
import GraphQLErrorList from '../components/blog/graphql-error-list'
import Layout from '../components/layout'
import Container from '../components/blog/container'
import SEO from '../components/seo'
import { toPlainText } from '../utils/bloghelpers'
import { Navbar } from '../components/navbar'
import { MDXRenderer } from 'gatsby-plugin-mdx'

export const query = graphql`
	query BlogPostTemplateQuery($id: String!) {
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
		post: post(id: { eq: $id }) {
			id
			publishedAt
			lang
			categories {
				objectId
				title
			}
			mainImage {
				alt
				idengagerfile {
					name
					url
				}
				externalurl
			}
			title
			slug {
				current
			}
			body {
				style
				createdAt
				updatedAt
				_xtype
				className
				children {
					text
					marks
					className
					_xtype
				}
			}
			excerpt {
				objectId
				className
				children {
					objectId
					className
					text
				}
			}
			authors {
				objectId
				image {
					crop {
						objectId
						_xtype
					}
					hotspot {
						objectId
						_xtype
					}
					idengagerfile {
						url
						name
					}
					externalurl
				}
				name
			}
		}
	}
`

const BlogPostTemplate = props => {
	const { data, errors } = props
	const post = data && data.post
	return (
		<Layout>
			{errors && <SEO title="GraphQL Error" />}
			{post && (
				<SEO
					title={post.title || 'Untitled'}
					description={toPlainText(post.excerpt)}
					image={post.mainImage.externalurl}
				/>
			)}
			{/* <h2 >Post Detail {JSON.stringify(post)}</h2> */}
			{errors && (
				<Container>
					<GraphQLErrorList errors={errors} />
				</Container>
			)}
			{/* Add header */}
			<Navbar />
			{post && <BlogPost {...post} />}
			<div className="footer-section">
				<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
			</div>
		</Layout>
	)
}

export default BlogPostTemplate
