import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import * as styles from './blog-side.module.css'
import { getBlogUrl, imageUrlFor } from '../../utils/bloghelpers'

const RecentBlog = () => {
	const data = useStaticQuery(graphql`
		{
			allPost(
				filter: { id: { ne: null }, publishedAt: { ne: null }, slug: { objectId: { ne: null } } }
				sort: { fields: publishedAt, order: DESC }
				limit: 3
			) {
				edges {
					node {
						id
						publishedAt
						mainImage {
							alt
							externalurl
							idengagerfile {
								name
								url
							}
						}
						title
						slug {
							current
						}
						categories {
							title
						}
					}
				}
			}
		}
	`)

	const postNodes = (data || {}).allPost.edges

	return (
		<aside className={styles.container}>
			<h3 className={styles.title}>Recent posts</h3>
			<ul className={styles.list}>
				{postNodes.map(({ node }) => {
					return (
						<Link to={getBlogUrl(node.publishedAt, node.slug)} key={node.id}>
							<li className={styles.sideList}>
								<div className={styles.sideImage}>
									<img
										src={node.mainImage?.externalurl}
										alt={node.mainImage?.alt}
									/>
									{/* {node.mainImage && node.mainImage.idengagerfile && (
                      <img
                        src={imageUrlFor(node.mainImage.externalurl)}
                        alt={node.mainImage.alt}
                      />
                    )} */}
								</div>
								<div>
									<h6 className={styles.sideTitle}>{node.title}</h6>
									<p className={styles.sideDate}>
										{node.publishedAt
											? new Date(
													node.publishedAt
											  ).toLocaleDateString(
													{},
													{
														timeZone: 'UTC',
														month: 'long',
														day: '2-digit',
														year: 'numeric',
													}
											  )
											: null}
									</p>
								</div>
							</li>
						</Link>
					)
				})}
			</ul>
		</aside>
	)
}

export default RecentBlog
