import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import * as styles from './blog-side.module.css'
import slugify from 'slugify'

const BlogCategory = ({ currentCateg }) => {
	const data = useStaticQuery(graphql`
		{
			allPost(
				filter: { id: { ne: null }, publishedAt: { ne: null }, slug: { objectId: { ne: null } } }
			) {
				group(field: categories___title) {
					totalCount
					fieldValue
				}
			}
		}
	`)

	// console.log('from blog categories', currentCateg)

	return (
		<aside className={styles.container}>
			<h3 className={styles.title}>Post categories</h3>
			<ul className={styles.list}>
				{data.allPost.group.map((category, index) => (
					<Link
						to={`/blogs?category=${slugify(category.fieldValue, { replacement: '-' })}`}
						key={index}
					>
						<li>
							{category.fieldValue} ({category.totalCount})
						</li>
					</Link>
				))}
			</ul>
		</aside>
	)
}

export default BlogCategory
