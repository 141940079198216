import { PortableText as BasePortableText } from '@portabletext/react'
import React from 'react'
// import clientConfig from '../../../idengager-config'
import { Figure } from './Figure'

// const components = {
// 	types: {
// 		/* eslint-disable-next-line react/display-name */
// 		authorReference: ({ node }) => <span>{node?.author?.name}</span>,
// 		mainImage: Figure,
// 	},
// }

// const test = {
// 	_key: 'e5a6349a2145',
// 	_type: 'block',
// 	children: [
// 		{
// 			_type: 'span',
// 			marks: [],
// 			text: 'Lorem ipsum',
// 		},
// 	],
// 	markDefs: [],
// 	style: 'normal',
// }

const PortableText = ({ blocks }) => {
	console.log('Blocks', blocks)
	return (
		<>
			{/* <pre>body Detail {JSON.stringify(blocks)}</pre> */}
			<BasePortableText
				value={blocks}
				components={{
					marks: {
						strong: ({ children, text }) => (
							// <strong style={{ display: text.endsWith('\n') ? 'block' : undefined }}>
							// 	{children}
							// </strong>
							<strong dangerouslySetInnerHTML={{__html: text.replace(/\n/g, "<br />")}}></strong>
						),
						emphasis: ({ children, text }) => (
							// <em style={{ display: text.endsWith('\n') ? 'block' : undefined }}>
							// 	{children}
							// </em>
							<em dangerouslySetInnerHTML={{__html: text.replace(/\n/g, "<br />")}}></em>
						),
						orderedList: ({ text }) => (
							<ol>
								{text.split('_').map((item, index) => {
									return <li key={index}>{item}</li>
								})}
							</ol>
						),
						unOrderedList: ({ text }) => {
							return (
								<ul style={{ listStyle: 'disc' }}>
									{text.split('_').map((item, index) => {
										return <li key={index}>{item}</li>
									})}
								</ul>
							)
						},
						link: ({ children, text }) => {
							const href = text.split('_')[0].replace('<', '').replace('>', '')
							return (
								<a href={href} target="_blank" rel="noopener noreferrer">
									{text.split('_')[1]}
								</a>
							)
						},
						null: ({ children }) => <>{children}</>,
					},
					types: {
						block: ({ children }) => {
							return <p>{children}</p>
						},
						span: props => (
							<span
								style={{
									display: props.value.text?.endsWith('\n')
										? 'block'
										: undefined,
								}}
								dangerouslySetInnerHTML={{ __html: props.value.text.replace(/\n/g, "<br />") }}
							>
							</span>
						),
						// Add more types as needed to match the structure of the JSON data
					},
				}}
				// components={}
				onMissingComponent={(message, options) => {
					console.log('message' + message + ' type ' + options.type)
				}}
			/>
			{/* <Figure node={blocks.externalurl} /> */}
		</>
	)
}

export default PortableText
