// extracted by mini-css-extract-plugin
export var root = "blog-post-module--root--1IurX";
export var header = "blog-post-module--header--1L7vX";
export var title = "blog-post-module--title--mUZtX typography-module--responsiveTitle1--qBuKa";
export var description = "blog-post-module--description--1znU5 typography-module--responsiveTitle4--U2dJc";
export var blueColor = "blog-post-module--blueColor--b6DRa";
export var mainImg = "blog-post-module--mainImg--30sZG";
export var blogBlock = "blog-post-module--blogBlock--iqgOS typography-module--paragraph--3OWYW";
export var sideBlog = "blog-post-module--sideBlog--P9C4o";
export var mainImage = "blog-post-module--mainImage--3rYsj";
export var grid = "blog-post-module--grid--1D8p5";
export var mainContent = "blog-post-module--mainContent--2Dzdn";
export var metaContent = "blog-post-module--metaContent--1D3kr";
export var publishedAt = "blog-post-module--publishedAt--31GIe typography-module--small--1SU-S";
export var categories = "blog-post-module--categories--12o0q";
export var categoriesHeadline = "blog-post-module--categoriesHeadline--2wgRA typography-module--base--35IWj";